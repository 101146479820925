import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { useTranslation } from 'react-i18next'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

const SharedProps = `
  color:white;
  font-family: "TT Norms Pro Medium";
  font-size: 12px;
  letter-spacing: 0.2em;
  margin:0 20px;
  text-decoration:none;
  text-transform:uppercase;
  line-height:28px;
`

const ImprintPrivacyWrapper = styled.div`
  display: flex;
  width: 75%;
  color: white;
  height: 100px;
`

const StyledLink = styled(Link)`
  ${SharedProps}
`

const MenuItem = styled(AnchorLink)`
  text-decoration: none;
  ${SharedProps}
`

const FooterWrapper = styled.footer`
  width: 100%;
  display: flex;
  margin: 0 auto 0 auto;
  max-width: 1200px;
  padding-bottom: 20px;
`

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Est = styled.p`
  ${SharedProps}
`

function Footer() {
  const { t } = useTranslation('')

  return (
    <FooterWrapper>
      <ImprintPrivacyWrapper>
        <Est>Est. 2018</Est>
        <StyledLink to={t('urls.imprint')}>Imprint</StyledLink>
        <StyledLink to={t('urls.privacy-policy')}>Privacy Policy</StyledLink>
      </ImprintPrivacyWrapper>
      <MenuWrapper>
        <MenuItem to={t('urls').talents}>{t('menuItems').talents}</MenuItem>
        <MenuItem to={t('urls').customers}>{t('menuItems').clients}</MenuItem>
        <MenuItem to={t('urls').process}>{t('menuItems').process}</MenuItem>
      </MenuWrapper>
    </FooterWrapper>
  )
}

export default Footer
