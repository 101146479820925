import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import Layout from '../components/Layout'
import Footer from '../components/Footer/Footer'
import Background from '../components/StreamerTemplate/Background'

const Wrapper = styled.section`
  width: 100%;
  min-height: 93vh;
  height: fit-content;
  display: flex;
  justify-content: center;
`

const ContentWrapper = styled.div`
  margin-top: 100px;
  margin-bottom: 100px;
  max-width: 600px;
  padding: 50px;
  color: #fff;
  text-align: center;

  a {
    color: #fff;
  }
`

const NotFound = () => {
  return (
    <Background>
      <Wrapper>
        <ContentWrapper>
          <h1>404: Page not found 😞</h1>
          <Link to="/">Click here to go back to the homepage</Link>
        </ContentWrapper>
      </Wrapper>
      <Footer />
    </Background>
  )
}

export default NotFound
